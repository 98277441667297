import React from "react"

import SEO from "../components/seo"

import img from "../images/undraw_under_construction_46pa.svg"

const IndexPage = () => (
  <div className="w-screen h-screen bg-grey">
    <SEO title="Home" />
    <div class="flex flex-col justify-center h-screen ">
      <img className=" w-auto h-auto my-8" src={img} alt="under construction" />
      <div class="text-gray-700 text-center text-xl text-bold font-bold">Black Wolf Contracting: Under Construction</div>
    </div>
  </div>
)

export default IndexPage
